export const PROTECTED_EARNINGS_RATE = 457310;

export default function rechner2024(wages, people) {
    let start = 150000;
    let addon = 0;
    let index = -1;

    if (wages < 9999) {
        wages *= 100;
    }

    if (people === 1) {
        start = 206000;
    }
    if (people === 2) {
        start = 237000;
    }
    if (people === 3) {
        start = 268000;
    }
    if (people === 4) {
        start = 300000;
    }
    if (people === 5) {
        start = 331000;
    }

    if (wages > PROTECTED_EARNINGS_RATE) {
        addon = wages - PROTECTED_EARNINGS_RATE;
        wages = PROTECTED_EARNINGS_RATE;
    }

    while (wages >= start) {
        start += 1000;
        index++;
    }

    if (index === -1) {
        return 0;
    }

    let startAmount = 578;
    let indexAmount = 700;

    if (people === 1) {
        startAmount = 341;
        indexAmount = 500;
    }
    if (people === 2) {
        startAmount = 162;
        indexAmount = 400;
    }
    if (people === 3) {
        startAmount = 38;
        indexAmount = 300;
    }
    if (people === 4) {
        startAmount = 170;
        indexAmount = 200;
    }
    if (people === 5) {
        startAmount = 57;
        indexAmount = 100;
    }

    return startAmount + (index * indexAmount) + addon;
}
